<template>
    <div class="container">
        <navbar-admin/>
        <el-row class="menu-icons">   
            <div class="icon" v-for="(menu,i) in filterMenus" :key="i"> 
                <el-tooltip v-if="menu.id_menu_superior == null" :content="`${menu.nombre}`" effect="light" placement="top" visible-arrow>
                    <el-button :class="`icon-${menu.icono} f-25 ${isActiveButton(menu.id)}`" @click="redirect(menu.id)" circle></el-button>
                </el-tooltip>
                <div>
                </div>
            </div>              
        </el-row> 
        <router-view/>       
    </div>
</template> 

<script>
import { mapActions, mapGetters } from 'vuex';
export default { 
    data() {
        return {
            idFirstSubMenu: null,
        }
    },
    computed: {
        ...mapGetters({
            menu: 'configurar/permisos/menus/menus',
            user_data: 'auth/user',

        }),
        menus: {
           get(){
             return this.menu
           },
           set(newName){
             return newName
           } 
        },
        id(){ 
            return this.$route.params.id
        },
        filterMenus(){
            let data = this.menus.filter(el => {
                return el.id_menu_superior == null
            })
            if (this.user_data.mostrar_configuracion == 1 && this.user_data.super_admin == 0) {
                data =  data.filter(el => {
                    const exists = this.user?.permiso_configuracion.find(e => `${e.nombre}`.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() == `${el.nombre}`.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim())
                    if (exists) return el
                })
            }
            return data
        }
    },
    watch:{
        id(){
        }
    },
    async created(){
        await this.getMenus()
        this.getBreadcumbs([ 'configurar.permisos', 'configurar.permisos.acciones' ]);
    },
    methods:{
        redirect(id){ 
            const idTab = this.getFirstSubMenu(id)
            this.$router.push({ name: 'configurar.permisos.acciones.menu', params: { id, tab: idTab  }})
        },
        ...mapActions({
            getMenus: 'configurar/permisos/menus/Action_get_menus',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
        }),
        isActiveButton(id) {
            return this.id == id ? 'is_active' : ''
        },
        getFirstSubMenu(id) {
            const first = this.menus.find(el => el.id_menu_superior == id)
            return first == null ? 0 : first.id
             
        }
    }
}
</script>

<style scoped lang="scss">
.container {
    padding: 20px;
}
.menu-icons {
    display: flex;
}
.icon {
    margin: 0 5px 0 5px;
}
.el-button {
    color: #dddddd;
    line-height: normal;
    padding: 5px;
    &:hover {
        background-color: var(--color-general);
        color: var(--bg-navbar);
    }
}
.is_active {
    background-color: var(--color-general);
    color: var(--bg-navbar);
}
</style>